<template>
  <div>
    <ayl-berad-nav :nav="nav"></ayl-berad-nav>
    <div class="content-box">
      <div class="content-main">
        <ayl-table :table="table">
          <div slot="ctrl-button">
            <el-button type="warning"
                       @click="$router.push('/operation/operations-management/add-operations-management')">新增
            </el-button>
            <el-button class="ml40px" type="info" style="float: right;" @click="dialogExport=true" plain>导出</el-button>
          </div>
        </ayl-table>
      </div>
    </div>
    <!--导出-->
    <el-dialog title="选择导出的数据" :visible.sync="dialogExport" width="540px" center>
      <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
      <el-checkbox-group v-model="checkIndex" @change="handleCheckedCitiesChange">
        <el-checkbox :label="item.index" v-for="(item, idx) in checkList" :key="idx">{{item.value}}</el-checkbox>
      </el-checkbox-group>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="exportExcel" :loading="btnLoading">确 定</el-button>
        <el-button @click="dialogExport=false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import mixins from '../../../utils/mixins'
  export default {
    mixins: [mixins.$exportCheckAll],
    data () {
      const vm = this
      return {
        nav: [{name: '往来单位管理', path: ''}, {name: '往来单位维护', path: ''}],
        dialogExport: false,
        checkList: [],
        checkIndex: [],
        btnLoading: false,
        table: {
          api: vm.$api.operationsList,
          query: {
            queryContent: null,
          },
          // 是否显示分页
          // hidePagination: true,
          // 表格查询的条件组件
          searchData: [
            {
              type: 'input',
              model: 'queryContent',
              placeholder: '往来单位名称'
            }],
          columns: [
            //   {
            //   title: '序号',
            //   width: '50px',
            //   $index: 'index',
            // },
            {
              title: '往来单位名称',
              key: 'partnerName',
              showTooltip: true,
            }, {
              title: '单位简写',
              key: 'partnerShorthand',
              showTooltip: true,
            }, {
              title: '联系人',
              width: '150px',
              render (h, ctx) {
                return h('span', [
                  h('span', {}, ctx.row.steelPartnerContactInfoVOList[0]&&ctx.row.steelPartnerContactInfoVOList[0].contactName||'- -')
                ])
              }
            },{
              title: '手机号码',
              width: '150px',
              render (h, ctx) {
                return h('span', [
                  h('span', {}, ctx.row.steelPartnerContactInfoVOList[0]&&ctx.row.steelPartnerContactInfoVOList[0].contactPhone||'- -')
                ])
              }
            }, {
              title: '客户地址',
              // width: '90px',
              key: 'companyAddress',
              showTooltip: true,
            }, {
              title: '操作',
              width: '105px',
              render (h, ctx) {
                return h('span', [
                  h('span', {
                    class: {
                      'table-view': true,
                    },
                    on: {
                      click: vm.goEdit.bind(this, ctx.row)
                    }
                  }, '编辑'),
                  // h('span', {
                  //   class: {
                  //     'table-delete': true
                  //   },
                  //   on: {
                  //     click: vm.delete.bind(this, ctx.row)
                  //   }
                  // }, '删除')
                ])
              }
            }]
        }
      }
    },
    methods: {
      //删除
      async delete (val) {
        await this.$confirm(`您确认删除【${val.partnerName}】吗?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
        await this.$api.operationsDelete({
          partnerId: val.partnerId
        })
        this.$notify({
          title: '成功',
          message: '删除成功',
          type: 'success'
        })
        await this.$search(this.table)
      },
      //编辑
      goEdit (val) {
        this.$router.push({
          path: '/operation/operations-management/edit-operations-management',
          query: {
            partnerId: val.partnerId,
          }
        })
      },
      // 导出
      async exportExcel(){
        this.btnLoading = true
        console.log(this.table.query.queryContent)
        try {
          const url = await this.$api.getExcel({
            params: {
              queryContent: this.table.query.queryContent,
            },
            excelKey: 'exportPartnerAndContact',
            indexList: this.checkIndex,
          })
          
          window.open(url)
          this.dialogExport = false
        // eslint-disable-next-line no-empty
        }catch (e) {}
        this.btnLoading = false
      },
    },
    async mounted () {
      await this.$search(this.table)
      this.$api.GetExcelExportKeyAndColumn({
        excelKey: "exportPartnerAndContact"
      }).then((res)=>{
        this.checkList = res.chsList
        this.checkIndex = this.checkList.map(e=>{
          return e.index
        })
      })
    },

    async activated() {
      await this.$search(this.table)
    }
  }
</script>

<style lang='sass' scoped>

</style>
